<script>

import { reactive, computed } from 'vue'

const allThingsNotes = reactive({
  currentNodeIdx: 0,
  notes: [
    'C',
    'Cis',
    'Des',
    'D',
    'Dis',
    'Es',
    'E',
    'F',
    'Fis',
    'Ges',
    'G',
    'Gis',
    'As',
    'A',
    'Ais',
    'B (AKA Bb)',
    'H (AKA B)'
  ]
});

function generateNewNumber() {
  var nextNodeNumber = allThingsNotes.currentNodeIdx;
  while (nextNodeNumber == allThingsNotes.currentNodeIdx) {
    nextNodeNumber = getRandomInt(allThingsNotes.notes.length)
  }
  allThingsNotes.currentNodeIdx = nextNodeNumber;
}

// a computed ref
const currentNoteAsString = computed(() => {
  return allThingsNotes.notes[allThingsNotes.currentNodeIdx];
});

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default {
  name: 'NoteViewer',
  setup() {

    generateNewNumber();

    return {
      currentNoteAsString,
      generateNewNumber
    }
  }
};
</script>

<template>
  <div>
    
    <h1><img class="logo" src="@/assets/note.png" />Tilfældig node-generator</h1>
    <p>Genererer et tilfældigt nodenavn indenfor en oktav. "Skæve" toner kan både være hævede (-is) eller sænkede (-es).
    </p>
  </div>
  <div class="note">{{ currentNoteAsString }}</div>
  <div>
    <button @click="generateNewNumber" class="generate_button">Ny tone</button>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.logo {
  width: 5%;
  padding: 0px;  
}
.note {
  font-size: 500%;
}

.generate_button {
  width: 50%;
  font-size: x-large;
}
</style>