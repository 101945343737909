<template>
  <NoteViewer/>
</template>

<script>
import NoteViewer from './components/NoteViewer.vue'

export default {
  name: 'App',
  components: {
    NoteViewer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;  
}


</style>
